import { FormGroup } from '@angular/forms';
import { IfDefinition } from '../core/utils/filter';
import { CustomDashboardFilterType } from '../dashboards/custom-dashboard-filters/custom-dashboard-filter.component';
import { ProgressIndicatorState } from '../shared/progress-indicator/progress-indicator.component';
import { ProjectResult } from './project-result';

export interface ProjectType {
  projectType: string;
}

export interface SearchResult {
  total: number;
  results: any[];
  aggregations: any[];
  nextPageToken: string;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export function isDateRange(field: unknown): field is DateRange {
  return (field as DateRange).start !== undefined || (field as DateRange).end !== undefined;
}

export interface SearchParameters {
  type?: string;
  delegateTenant?: string | null;
  subTenantIds?: string;
  status?: string;

  createdBefore?: string;
  createdAfter?: string;
  updatedBefore?: string;
  updatedAfter?: string;
  scheduledBefore?: string;
  scheduledAfter?: string;

  contactLogAttempts?: number | number[];

  documentNames?: string;
  documentStatuses?: string;

  ownerName?: string;
  sipModuleStatus?: string;

  enaModuleDnoStatus?: string;

  sortBy?: string;
  sortOrder?: string;

  maxResults?: number;
  [key: string]: string | string[] | number | number[] | DateRange;
}

export interface CustomDashboardFilter {
  field: string;
  display: string;
  type: CustomDashboardFilterType;
  position: CustomDashboardPositionType;
}

export interface FilterDropDownElement {
  id: string;
  name?: string;
  label?: string;
  sticky?: boolean;
  [key: string]: unknown;
}

export interface ProjectFiltersDm {
  projectTypes: FilterDropDownElement[];
  projectStatuses?: FilterDropDownElement[];
  delegates: FilterDropDownElement[];
  subTenants: FilterDropDownElement[];
  contactAttempts: FilterDropDownElement[];
  documents: FilterDropDownElement[];
  documentStates: FilterDropDownElement[];
  owners: FilterDropDownElement[];
  teams: FilterDropDownElement[];

  customFilters: CustomDashboardFilter[];
  advancedFilters: CustomDashboardFilter[];

  contactLoggingEnabled: boolean;
  documentPackEnabled: boolean;
  ownerFilterEnabled: boolean;

  filtersForm: FormGroup;

  i18n: {
    projectTypeLabel: string;
    projectStatusLabel: string;
    installerLabel: string;
    documentsLabel: string;
    documentsStatusLabel: string;
    ownerLabel: string;
    assignmentLabel: string;
    sipModuleStatusLabel: string;
    enaModuleDnoStatusLabel: string;
  };
}

export interface ProjectsDm {
  showLoaders: boolean;
  userTenant: string;

  projectStates: ProgressIndicatorState[];

  projectTypes: FilterDropDownElement[];
  projectStatuses?: FilterDropDownElement[];
  delegates: FilterDropDownElement[];
  subTenants: FilterDropDownElement[];
  contactAttempts: FilterDropDownElement[];
  documents: FilterDropDownElement[];
  documentStates: FilterDropDownElement[];
  owners: FilterDropDownElement[];
  teams: FilterDropDownElement[];
  sipModuleStatuses: FilterDropDownElement[];
  enaModuleDnoStatuses: FilterDropDownElement[];

  customFilters: CustomDashboardFilter[];
  advancedFilters: CustomDashboardFilter[];

  contactLoggingEnabled: boolean;
  documentPackEnabled: boolean;
  ownerFilterEnabled: boolean;
  sipModuleEnabled: boolean;
  enaModuleEnabled: boolean;

  fields?: DashboardField[];

  searchParameters: SearchParameters;

  searchInProgress: boolean;
  result: {
    page: number;
    total: number;
    projects: ProjectVm[];
    nextPageToken?: string;
  };

  exportInProgress: boolean;
  showExport: boolean;

  i18n: {
    projectTypeLabel: string;
    projectStatusLabel: string;
    installerLabel: string;
    documentsLabel: string;
    documentsStatusLabel: string;
    ownerLabel: string;
    projectsFoundLabel: string;
    projectFoundLabel: string;
    assignmentLabel: string;
    pageSizeLabel: string;
    sipModuleStatusLabel: string;
    enaModuleDnoStatusLabel: string;
  };

  customDashboardFields?: DashboardField[];
  exportConfiguration?: ExportConfiguration;
}

export interface ProjectsVm {
  showLoaders: boolean;
  userTenant: string;
  projectTypeLabel: string;
  projectStatusLabel: string;
  installerLabel: string;
  documentsLabel: string;
  documentsStatusLabel: string;
  ownerLabel: string;
  projectsFoundLabel: string;
  projectFoundLabel: string;
  assignmentLabel: string;
  pageSizeLabel: string;
  sipModuleStatusLabel: string;
  enaModuleDnoStatusLabel: string;

  projectStates: ProgressIndicatorState[];

  projectTypes: FilterDropDownElement[];
  projectStatuses?: FilterDropDownElement[];

  delegates: FilterDropDownElement[];
  subTenants: FilterDropDownElement[];

  contactLoggingEnabled: boolean;
  contactAttempts: FilterDropDownElement[];

  documentPackEnabled: boolean;
  documents: FilterDropDownElement[];
  documentStates: FilterDropDownElement[];

  ownerFilterEnabled: boolean;
  owners: FilterDropDownElement[];

  teams: FilterDropDownElement[];

  sipModuleStatuses: FilterDropDownElement[];
  sipModuleEnabled: boolean;

  enaModuleDnoStatuses: FilterDropDownElement[];
  enaModuleEnabled: boolean;

  customFilters: CustomDashboardFilter[];
  advancedFilters: CustomDashboardFilter[];

  fields: DashboardField[];

  searchParameters: {
    types?: string[];
    statuses?: string[];
    delegates?: string[];
    subTenants?: string[];
    owners?: string[];
    documents?: string[];
    documentStates?: string[];
    eventFilterType?: string;
    eventFilterDateRange?: { start?: Date; end?: Date };
    contactAttempts?: number | number[];
    nextPageToken?: string;
    previousPageToken?: string;
    sortBy?: string;
    sortOrder?: string;
    maxResults?: number;
    sipModuleStatuses?: string[];
    enaModuleDnoStatuses?: string[];
  };

  searchInProgress: boolean;
  result: {
    page: number;
    total: number;
    projects: ProjectVm[];
  };

  exportInProgress: boolean;
  showExport: boolean;

  qaProjectTypeFilter: string;
  qaInstallerFilter: string;
  qaSubTenantFilter: string;
  qaStatusFilter: string;
  qaDocumentsFilter: string;
  qaDocumentStatesFilter: string;
  qaOwnerFilter: string;
  qaProjectHeaderRow: string;
  qaColumnHeaderPrefix: string;
  qaSortArrow: string;
  qaNoResults: string;
  qaProjectRow: string;
  qaCol: string;
  qaRow: string;
  qaDelegationButtonSuffix: string;
  qaUnDelegationButtonSuffix: string;
  qaSearchInProgress: string;
  qaSipModuleStatusFilter: string;
  qaEnaModuleDnoStatusFilter: string;
}

export type ProjectVm = ProjectResult;

export enum CustomDashboardPositionType {
  MAIN = 'MAIN',
  ADVANCED = 'ADVANCED'
}

export interface DashboardField {
  field: string;
  display?: string;
  context?: {
    [key: string]: IfDefinition;
  };
  sortField?: string;
  noSort?: boolean;
  maxLength?: number;
  after?: string;
  before?: string;
  pos?: number;
  remove?: boolean;
}

export function isDashboardField(field: ExportField | DashboardField): field is DashboardField {
  return (field as DashboardField).display !== undefined;
}

export interface ExportField {
  field: string;
  header: string;
  context?: {
    [key: string]: IfDefinition;
  };
  alias?: string;
  after?: string;
  before?: string;
  pos?: number;
  remove?: boolean;
}
export function isExportField(field: ExportField | DashboardField): field is ExportField {
  return (field as ExportField).header !== undefined;
}

export interface ExportConfiguration {
  prefix: string;
  fields: ExportField[];
  excludePii?: boolean;
}
